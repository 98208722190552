import React from 'react';


export default function MiddleVideo({id}) {
    return (
        <React.Fragment>
            <div className="videoWrapper">
            <iframe src={`https://www.youtube.com/embed/${id}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </React.Fragment>
    );
}
