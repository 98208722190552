import React from 'react';
import Slider from "react-slick";
import "../../node_modules/slick-carousel/slick/slick.css";
import "../../node_modules/slick-carousel/slick/slick-theme.css";
import sliderStyles from '../assets/scss/image-slider.module.scss'

import img from '../images/home-slider.png'

const imgBase = process.env.imgBase;

export default function ImageSlider({slides}) {

    const settings = {
        dots: true,
        infinite: false,
        arrows: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    console.log('bahiii', slides);

    return (
        <React.Fragment>
            <div className="SliderComponent">
                <Slider  {...settings}>
                    {
                        JSON.parse(slides).length > 0? 
                            JSON.parse(slides).map((img) => (
                                <div>
                                    <img src={`${imgBase}${img}`}/>
                                </div>
                            ))
                            :
                            ''
                    }
                </Slider>
            </div>
        </React.Fragment>
    );
}
