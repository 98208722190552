import { Button, Container, Grid } from '@material-ui/core';
import React from 'react';
import headerStyles from '../assets/scss/header_and_text.module.scss'
import GridRow from './_grid-row';
import Collapse from '@material-ui/core/Collapse';

export default function HeaderAndText({subtitle, title, desc}) {

    const [checked, setChecked] = React.useState(false);

    const handleChange = () => {
        setChecked((prev) => !prev);
    };


    return (
        <React.Fragment>
            <div className={headerStyles.wrapper}>
                <Container maxWidth="xl">
                    <GridRow >
                        <Grid container spacing={3} justify="center">
                            <Grid item xs={12} lg={10} >
                                <div className={["text-02", headerStyles.subhead].join(" ")}>{subtitle}</div>
                                <h1 className={["h1", headerStyles.header].join(" ")}>{title}</h1>
                                <div className={["text-01", headerStyles.description].join(" ")} dangerouslySetInnerHTML={{__html: desc? desc.replace(/\r\n|\r|\n/g,"<br />") : ''}}></div>
                                {/* <Collapse in={checked}>
                                    <div className={["text-01", headerStyles.description].join(" ")}>Welcome to Beach Monkeyz, let’s get stronger than yesterday. We have an event coming up and we’d love to have you on our team.</div>
                                </Collapse> */}
                                {/* <div className={["button", headerStyles.button].join(" ")}>
                                    <Button type="button" onClick={handleChange}>Learn more <i className="arrow down"></i></Button>
                                </div> */}
                            </Grid>
                        </Grid>
                    </GridRow>
                </Container>
            </div>
        </React.Fragment>
    );
}
