import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import GridRow from './_grid-row';
import CardActionArea from '@material-ui/core/CardActionArea';
import cardsStyles from '../assets/scss/cards.module.scss';




const imgBase = process.env.imgBase;

export default function CardBox({cardHeader,img,cardPara}) {

    return (
        <Grid item xs={12} md={4} lg={3}>
            <Card className={cardsStyles.card}>
               
                    <div className={cardsStyles.cardsBg}>
                        <h2 className={["h2", cardsStyles.cardsH].join(" ")}>{cardHeader}</h2>
                    </div>
                    <img src={`${imgBase}${img}`} />

                    <p className={["body-02", cardsStyles.cardsPara].join(" ")} dangerouslySetInnerHTML={{__html: cardPara? cardPara.replace(/\r\n|\r|\n/g,"<br />") : ''}}></p>
                

            </Card>
        </Grid>
    );
}
