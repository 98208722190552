import React from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';


import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import GridRow from './_grid-row';
import CardActionArea from '@material-ui/core/CardActionArea';
import img from '../images/lenghten-rounded.png';
import img1 from '../images/strengthen-rounded.png';
import img2 from '../images/ground.png';
import img3 from '../images/groove-rounded.png';
import cardsStyles from '../assets/scss/cards.module.scss';

import CardBox from './card_box';





export default function Cards({cards}) {
  return (
    <React.Fragment>

      <Container maxWidth="xl"className={`${cardsStyles.cardsContainer} cardsContainer`}  >
        <GridRow>
          <Grid container spacing={3}>
            {
              cards ? cards.map(card => (
                <CardBox 
                  img = {card.images}
                  cardHeader = {card.title}
                  cardPara = {card.description}
                />
              )) : ''
            }
            
          </Grid>
        </GridRow>
      </Container>
    </React.Fragment>
  );
}
