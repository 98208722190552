import * as React from "react"
import { useContext, useEffect, useState } from 'react';
import Layout from "../components/layout"
import Seo from "../components/seo"
import Video from "../components/video"
import MiddleVideo from "../components/middle-video"
import Cards from "../components/cards"
import  CommingSoon from "../components/comming-soon"
import HeaderAndText from '../components/header_and_text'
import ImageAndButton from '../components/image_and_button'
import ImageSlider from "../components/image-slider"
import Calander from "../components/calander"
import ApiContext from "../providers/api"
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";
const spinner = css`
  display: block;
  margin: 0 auto;
`;

const IndexPage = () => {
  const { getHomeData } = useContext(ApiContext);
  const [isLoading, setIsLoading] = useState(true);
  const [ homeData, setHomeData ] = useState({
    HomePage : {
      header_video_id: '',
      header_subtitle: '',
      header_title: '',
      header_description: '',
      images: '[]',
      second_video_id: '',
      ready_to_join_title: '',
      ready_to_join_description: '',
      foorter_title: '',
      foorter_desc: '',
      foorter_image: '',
      class_title: '',
      class_description: ''
    }, 
    Cards: []
  });
  
  useEffect(async () => {
    let resHomeData = await getHomeData();
    setIsLoading(false);
    console.log('bahii', resHomeData)
    setHomeData(resHomeData);
    
  }, []);
  
  return (
  <Layout pageName="Home" noMargin>
    <Seo title="Home" />
    { isLoading ? 
      <div className="loaderWrapper">
          <ClipLoader
              css={spinner}
              size={150}
              color={"#fada4d"}
          />
      </div> : 
      <React.Fragment>
        <Video id = {homeData.HomePage.header_video_id}/>
        <HeaderAndText 
          subtitle = {homeData.HomePage.header_subtitle}
          title = {homeData.HomePage.header_title}
          desc = {homeData.HomePage.header_description}
        />
        <MiddleVideo id = {homeData.HomePage.second_video_id}/>
        <Calander 
          class_title = {homeData.HomePage.class_title}
          class_description = {homeData.HomePage.class_description?homeData.HomePage.class_description : ''}
        />
        <ImageSlider slides={homeData.HomePage.images}/>
        <Cards cards={homeData.Cards}/>
        <ImageAndButton 
          ready_to_join_title = {homeData.HomePage.ready_to_join_title}
          ready_to_join_description = {homeData.HomePage.ready_to_join_description}
        />
        <CommingSoon 
          foorter_title = {homeData.HomePage.foorter_title}
          foorter_desc = {homeData.HomePage.foorter_desc}
          foorter_image = {homeData.HomePage.foorter_image}
        />
      </React.Fragment>
    }
  </Layout>
)}

export default IndexPage
