import { Container, Grid } from '@material-ui/core';
import React from 'react';
import imageStyles from '../assets/scss/image_and_button.module.scss'
import GridRow from './_grid-row';
import img from '../images/celebrate-logo.png'
import { Link } from 'gatsby';

export default function ImageAndButton({ready_to_join_title, ready_to_join_description}) {
    return (
        <React.Fragment>
            <div className={imageStyles.wrapper}>
                <Container maxWidth="xl">
                    <GridRow >
                        <Grid container spacing={3}>
                            <Grid item xs={3} lg={2}>
                                <img className={["img-responsive", imageStyles.img].join(" ")} src={img} />
                            </Grid>
                            <Grid item xs lg={8}>
                                <div className={imageStyles.textWrapper}>
                                    <div>
                                        <h2 className={["h1", imageStyles.header].join(" ")}>{ready_to_join_title}</h2>
                                        <div className={["text-01", imageStyles.text].join(" ")} dangerouslySetInnerHTML={{__html: ready_to_join_description ? ready_to_join_description.replace(/\r\n|\r|\n/g,"<br />") : ''}}></div>
                                    </div>
                                    {/* <Link to="/register" className={["button", imageStyles.button].join(" ")}>Register now</Link> */}
                                </div>
                            </Grid>
                        </Grid>
                    </GridRow>
                </Container>
            </div>
        </React.Fragment>
    );
}
