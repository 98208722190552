import React, { useEffect } from 'react';


export default function Video({id}) {
    
    return (
        <React.Fragment>
            <div className="videoWrapper">
                {/* <div className="videoOverlay"></div> */}
                <div className="videoSection">
                <iframe class="embed-responsive-item" src={`https://www.youtube.com/embed/${id}?rel=0&autoplay=1&mute=1&disablekb=1&fs=0&modestbranding=1&playsinline=1`} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allow="autoplay" allowfullscreen></iframe>
                </div>
            </div>
        </React.Fragment>
    );
}
