import React from 'react';
import { Link } from "gatsby";
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import GridRow from './_grid-row';
import img from '../assets/images/soonn.png';
import commingStyles from '../assets/scss/comming.module.scss';



const imgBase = process.env.imgBase;

export default function CommingSoon({foorter_title, foorter_desc, foorter_image}) {

    return (
        <React.Fragment>

            <div className={commingStyles.soonBg}>
                <Container maxWidth="xl" className={commingStyles.soonContainer}  >
                    <GridRow>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <img src={`${imgBase}${foorter_image}`} />

                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className={commingStyles.soonContent}>
                                    <div >
                                        <h2 className={["h1", commingStyles.soonH].join(" ")}>{foorter_title}</h2>
                                    </div>

                                  
                                    <p className={["text-01", commingStyles.soonPara].join(" ")} dangerouslySetInnerHTML={{__html: foorter_desc? foorter_desc.replace(/\r\n|\r|\n/g,"<br />") : ''}}></p>
                                 
                                    
                                        {/* <Link className={["button", commingStyles.link].join(" ")} to="#">Notify me at launch</Link> */}
                                    
                                </div>

                            </Grid>
                        </Grid>
                    </GridRow>
                </Container>
            </div>
        </React.Fragment>
    );
}
